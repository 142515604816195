<template>
  <b-row>
    <b-col cols="12">
      <b-overlay
        :show="$apollo.loading"
        rounded="sm"
        spinner-variant="primary"
      >
        <b-card>
          <b-card-header
            class="justify-content-start p-0 pb-1 mb-1 border-bottom"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              @click="$router.back()"
            >
              <feather-icon
                class="mr-25"
                icon="ChevronLeftIcon"
              />
            </b-button>
            <h3 class="mb-0 ml-2">
              All Applications {{ applicant ? `by ${applicant.title}` : '' }}
            </h3>
          </b-card-header>
          <!-- search input -->
          <div class="custom-search d-flex justify-content-start">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Search</label>
                <b-form-input
                  v-model="searchTerm"
                  class="d-inline-block mr-1"
                  placeholder="Search"
                  type="text"
                />
              </div>
            </b-form-group>
          </div>

          <!-- table -->
          <vue-good-table
            :columns="columns"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
            :rows="rows"
            :rtl="direction"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- Column: Status -->
              <span
                v-if="props.column.field === 'title'"
                class="d-flex justify-content-between align-items-center"
              ><span><b-badge

                       v-b-tooltip="'Round Number'"
                       class="mr-50 mb-25"
                       variant="primary"
                     >{{ props.row.round_number }}</b-badge>
                 {{ props.row.title }}</span>
                <span>
                  <b-button
                    class="mr-50"
                    size="sm"
                    variant="outline-primary"
                    :to="{ name: 'application-question', params: { pid: $route.params.pid, apid: props.row.id, aid:applicant.id }, query: {readonly: 'true'} }"
                  >
                    <span>View</span>
                  </b-button><feather-icon
                    fill="currentColor"
                    :class="props.row.programs_applicationsquestionstables.length && props.row.programs_applicationsquestionstables[0].programs_applicationresponsetables_aggregate.aggregate.count?'text-success':'text-danger'"
                    icon="CircleIcon"
                  /></span>
              </span>

              <span
                v-else-if="props.column.label === 'Status'"
                class="d-flex justify-content-between align-items-center"
              >
                {{ props.formattedRow[props.column.field] || '-' }}
                <b-button
                  size="sm"
                  variant="flat-primary"
                  @click="selectedRow=props.row.originalIndex; $bvModal.show('recommendationModal')"
                >Update</b-button>
              </span>
              <!-- Column: Action -->
              <span v-else-if="props.column.field === 'action'">
                <b-button
                  size="sm"
                  variant="outline-primary"
                  :to="{ name: 'view-evaluation', params: { pid: $route.params.pid, apid: props.row.id, aid:$route.params.aid}}"
                >
                  <span>View Evaluation</span>
                </b-button>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap ">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> of {{ props.total }} entries </span>
                </div>
                <div>
                  <b-pagination
                    :per-page="pageLength"
                    :total-rows="props.total"
                    :value="1"
                    align="right"
                    class="mt-1 mb-0"
                    first-number
                    last-number
                    next-class="next-item"
                    prev-class="prev-item"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>
      </b-overlay>
      <b-modal
        id="recommendationModal"
        ok-only
        no-close-on-backdrop
        ok-title="Update"
        title="Recommendations"
        @ok="updateStatus"
      >
        <div v-if="rows[selectedRow]">
          <b-form-group
            label="Recommendations"
          >
            <b-form-input
              v-model="rows[selectedRow].programs_applicantprogresstables[0].recommendation"
              placeholder="Your recommendation for startup"
            />
          </b-form-group>
          <b-form-group
            label="Status"
          >
            <v-select
              v-model="rows[selectedRow].programs_applicantprogresstables[0].status"
              :disabled="rows[selectedRow].programs_applicantprogresstables[0].status === 'Promoted'"
              :options="['Promoted', 'Declined']"
              placeholder="Select from list"
            />
          </b-form-group>
        </div>
      </b-modal>
    </b-col>
  </b-row>
</template>

<script>
/* eslint-disable */
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardHeader,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow
} from 'bootstrap-vue'
import {VueGoodTable} from 'vue-good-table'
import store from '@/store'
import gql from 'graphql-tag'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BCardHeader,
    BDropdown,
    BDropdownItem,
    BButton,

    vSelect
  },
  data() {
    return {
      selectedRow: null,
      pageLength: 5,
      dir: false,
      filter: {
        status: 'Current',
      },
      applicant: {
        id: '',
        title: '',
      },
      columns: [

        {
          label: 'Application',
          field: 'title',
        },
        {
          label: 'Recommendation',
          field(row) {
            return row.programs_applicantprogresstables[0].recommendation
          },
        },
        {
          label: 'Status',
          field(row) {
            return row.programs_applicantprogresstables[0].status
          },
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    currentApplicationId() {
      if (this.selectedRow === null) return null
      if (this.rows[this.selectedRow].programs_applicantprogresstables[0].status === 'Promoted') {
        return this.rows[this.selectedRow + 1] ? this.rows[this.selectedRow + 1].id : null
      } else {
        return this.rows[this.selectedRow].id
      }
    }
  },
  methods: {
    updateStatus() {
      this.mutationLoading = true
      const mutationObject = {
        current_application_form_id: this.currentApplicationId,
        status: this.rows[this.selectedRow].programs_applicantprogresstables[0].status === 'Declined' ? 'declined' : '',
        id: this.$route.params.aid,
        programs_applicantprogresstables: {
          data: {
            id: this.rows[this.selectedRow].programs_applicantprogresstables[0].id,
            application_form_id: this.rows[this.selectedRow].id,
            recommendation: this.rows[this.selectedRow].programs_applicantprogresstables[0].recommendation,
            status: this.rows[this.selectedRow].programs_applicantprogresstables[0].status
          },
          on_conflict: {
            constraint: 'programs_applicantprogresstable_pkey',
            update_columns: ['status', 'recommendation']
          }
        }
      }
      if (!mutationObject.programs_applicantprogresstables.data.id) {
        delete mutationObject.programs_applicantprogresstables.data.id
      }
      if (this.selectedRow === this.rows.length - 1 && this.rows[this.selectedRow].programs_applicantprogresstables[0].status === 'Promoted') {
        mutationObject.status = 'accepted'
        this.$apollo.mutate(
            {
              mutation: gql`mutation {
              insert_programs_startupparticipants_one(object: {applicant_id: ${this.$route.params.aid}, program_id: ${this.$route.params.pid}}) {
                id
              }
            }`,
              update: (store, {data: {insert_programs_startupparticipants_one}}) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: insert_programs_startupparticipants_one.id ? 'Applicant promoted to participant' : 'Failed to update',
                    icon: insert_programs_startupparticipants_one.id ? 'CheckIcon' : 'XIcon',
                    variant: insert_programs_startupparticipants_one.id ? 'success' : 'warning',
                  },
                })
                this.mutationLoading = false
                this.$apollo.queries.rows.refetch()
              },
            }
        )
      }
      this.$apollo.mutate(
          {
            mutation: gql`mutation MyMutation($object: programs_applicantstable_insert_input!) {
            insert_programs_applicantstable_one(object: $object, on_conflict: {constraint: programs_applicantstable_pkey, update_columns: [current_application_form_id, status]}) {
              id
            }
          }`,
            variables: {
              object: mutationObject
            },
            update: (store, {data: {insert_programs_applicantstable_one}}) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: insert_programs_applicantstable_one.id ? 'Updated successfully' : 'Failed to update',
                  icon: insert_programs_applicantstable_one.id ? 'CheckIcon' : 'XIcon',
                  variant: insert_programs_applicantstable_one.id ? 'success' : 'warning',
                },
              })
              this.mutationLoading = false
              this.$apollo.queries.rows.refetch()
            },
          }
      )
    }
  },
  apollo: {
    rows: {
      query() {
        return gql`
        {
          programs_applicationformstable(where: {program_id: {_eq: ${this.$route.params.pid}}}, order_by: {round_number:asc}) {
            id
            title
            round_number
            programs_applicationsquestionstables{
              programs_applicationresponsetables_aggregate(where: {applicant_id: {_eq: ${this.$route.params.aid}}}){
                aggregate{
                  count
                }
              }
            }
            programs_applicantprogresstables(where: {applicant_id_id: {_eq: ${this.$route.params.aid}}}) {
              id
              status
              recommendation
            }
          }
        }`
      },
      update(data) {
        data.programs_applicationformstable.forEach(e => {
          if (e.programs_applicantprogresstables.length < 1) {
            e.programs_applicantprogresstables.push({
              id: '',
              recommendation: '',
              status: '',
            })
          }
        })
        return data.programs_applicationformstable
      },
    },
    applicant: {
      query() {
        return gql`{
          programs_applicantstable_by_pk(id: "${this.$route.params.aid}") {
            users_organizationtable {
              id
              title
            }
          }
        }`
      },
      update: data => data.programs_applicantstable_by_pk.users_organizationtable,
    }
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
